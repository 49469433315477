import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import debounce from "lodash.debounce";
import ReactMarkdown from "react-markdown";
import "./ProductForm.css";

const WAIT_TIME_MS = 1000; // Constant for wait time

const ProductForm = ({ setRecommendations, sessionId, onSearchComplete, input, setInput, setIsLoading, setIsLoadingLong, setStoredInput, description, setJobs, setIsLoadingNoText }) => {
  const [specificSite, setSpecificSite] = useState();
  const [numLinks, setNumLinks] = useState(6);
  const [numRecommendations, setNumRecommendations] = useState(6);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [searchInput, setSearchInput] = useState(input);
  const [suggestions, setSuggestions] = useState([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [response, setResponse] = useState("");
  const [isEscPressed, setIsEscPressed] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);
  const fetchSuggestionsRef = useRef(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [searchDuration, setSearchDuration] = useState(0);
  const [activeTab, setActiveTab] = useState("newSearch"); // New state for active tab
  const [similarProductLink, setSimilarProductLink] = useState(""); // New state for similar product link
  const [questionInput, setQuestionInput] = useState(""); // New state for question input
  const [lastSearchInput, setLastSearchInput] = useState(""); // New state for storing the last search input
  const [searchMadeInTabs, setSearchMadeInTabs] = useState({
    newSearch: false,
    similarProduct: false,
    askQuestion: false,
    guidedSearch: false,
  });
  const [guidedQuestions, setGuidedQuestions] = useState([]);
  const [guidedAnswers, setGuidedAnswers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeepSearchInitiated, setIsDeepSearchInitiated] = useState(false);

  const resetStates = () => {
    setSearchInput(""); // Clear the input field
    setSuggestions([]); // Remove the suggestions
    setIsOutsideClicked(false); // Reset the outside click state
  };

  const handleSearch = async (searchValue, isDeepSearch = false) => {
    if (isDeepSearch) {
      setIsLoadingLong(true);
    } else {
      setIsLoading(true);
    }
    setIsLoadingLocal(true);
    setIsFormSubmitted(true);
    setIsEscPressed(false); // Reset the esc press state
    const startTime = Date.now();

    try {
      const searchQuery = searchValue.trim();
      const endpoint = isDeepSearch ? 'deep_search' : 'search';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        params: {
          user_input: searchQuery,
          num_links_to_scan: numLinks,
          num_recommendations: numRecommendations,
          specific_site: specificSite,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setRecommendations(response.data.recommendations || []); // Ensure recommendations is always an array
      setResponse(response.data.description); // Set the description from the search API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(searchValue); // Store the input value
      setLastSearchInput(searchValue); // Store the last search input
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab

      if (isDeepSearch) {
        setJobs(response.data.jobs || {}); // Update jobs with the response data
      }
    } catch (error) {
      console.error("Error fetching product recommendations:", error);
    } finally {
      if (isDeepSearch) {
        setTimeout(() => {
          setIsLoading(false);
          setIsLoadingLong(false);
          setIsLoadingLocal(false);
          setIsFormSubmitted(false);
          setIsEscPressed(false); // Reset the esc press state after search
        }, WAIT_TIME_MS);
      } else {
        setIsLoading(false);
        setIsLoadingLong(false);
        setIsLoadingLocal(false);
        setIsFormSubmitted(false);
        setIsEscPressed(false); // Reset the esc press state after search
      }
    }
  };

  const fetchSuggestions = async (value) => {
    if (isFetchingSuggestions || isEscPressed) return;
    setIsFetchingSuggestions(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/suggestions`, {
        params: {
          partial_input: value,
          num_suggestions: 5,
        },
      });
      if (!isFormSubmitted) {
        setSuggestions(response.data.suggestions || []); // Ensure suggestions is an array
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]); // Set suggestions to an empty array on error
    } finally {
      setIsFetchingSuggestions(false);
    }
  };

  const debouncedFetchSuggestions = useRef(debounce(fetchSuggestions, 300)).current;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    // Reset the outside click state when user types something
    setIsOutsideClicked(false);

    if (value.length === 0) {
      setIsEscPressed(false); // Reset the esc press state when input is cleared
    }

    // if (value.length > 0 && !isEscPressed) {
    //   debouncedFetchSuggestions(value);
    // } else {
    //   setSuggestions([]);
    // }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
    handleSearch(suggestion); // Confirm that the suggestion will be used as the product to search for
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim() === "") return; // Prevent submission if input is empty
    handleSearch(searchInput);
    resetStates(); // Reset states after form submission
  };

  const handleDeepSearchSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim() === "") return; // Prevent submission if input is empty
    setIsLoadingNoText(true); // Set loading state
    handleSearch(searchInput, true);
    resetStates(); // Reset states after form submission
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.inputGroup')) {
      setSuggestions([]);
      setIsOutsideClicked(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSuggestions([]);
      setIsEscPressed(true);
    }
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === "Enter" && !(e.shiftKey || e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      if (activeTab === "newSearch") {
        handleFormSubmit(e);
      } else if (activeTab === "similarProduct") {
        handleSimilarProductFormSubmit(e);
      } else if (activeTab === "askQuestion") {
        handleQuestionFormSubmit(e);
      } else if (activeTab === "guidedSearch") {
        handleGuidedSearchFormSubmit(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleShowMeClick = () => {
    handleSearch("Show me products based on the last question I asked.");
  };

  const handleSiteButtonClick = (site) => {
    setSpecificSite(site);
  };

  const handleTextareaInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight - 20}px`;
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSimilarProductSearch = async (link) => {
    setIsLoading(true);
    setIsLoadingLocal(true);
    const startTime = Date.now();

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/find_similar`, {
        params: {
          product_link: link,
          num_recommendations: numRecommendations,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setRecommendations(response.data.recommendations || []); // Ensure recommendations is always an array
      setResponse(response.data.description); // Set the description from the search API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(link); // Store the input value
      setLastSearchInput(link); // Store the last search input
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab
    } catch (error) {
      console.error("Error fetching similar product recommendations:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingLocal(false);
    }
  };

  const handleSimilarProductDeepSearch = async (link) => {
    setIsLoadingLong(true);
    setIsLoadingLocal(true);
    const startTime = Date.now();

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/find_similar`, {
        params: {
          url: link,
          num_recommendations: numRecommendations,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setRecommendations(response.data.recommendations || []); // Ensure recommendations is always an array
      setResponse(response.data.description); // Set the description from the search API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(link); // Store the input value
      setLastSearchInput(link); // Store the last search input
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab
    } catch (error) {
      console.error("Error fetching similar product recommendations:", error);
    } finally {
      setTimeout(() => {
        setIsLoadingLong(false);
        setIsLoadingLocal(false);
      }, WAIT_TIME_MS);
    }
  };

  const handleSimilarProductInputChange = (e) => {
    setSimilarProductLink(e.target.value);
  };

  const handleSimilarProductFormSubmit = (e) => {
    e.preventDefault();
    if (similarProductLink.trim() === "") return; // Prevent submission if input is empty
    handleSimilarProductSearch(similarProductLink);
  };

  const handleSimilarProductDeepSearchSubmit = (e) => {
    e.preventDefault();
    if (similarProductLink.trim() === "") return; // Prevent submission if input is empty
    handleSimilarProductDeepSearch(similarProductLink);
    setSimilarProductLink(""); // Clear the input field
  };

  const handleQuestionInputChange = (e) => {
    setQuestionInput(e.target.value);
  };

const handleQuestionFormSubmit = async (e) => {
  e.preventDefault();
  if (questionInput.trim() === "") return; // Prevent submission if input is empty
  setIsLoadingNoText(true); // Start spinner with no text
  setIsLoadingLocal(true);
  const startTime = Date.now();

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/question`, {
      params: {
        question: questionInput,
        session_id: sessionId, // Pass session ID to maintain context
      },
    });
    const endTime = Date.now();
    setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
    setResponse(response.data.answer); // Set the answer from the question API response
    onSearchComplete(); // Hide the form after search
    resetStates(); // Reset states after search
    setSearchMade(true); // Indicate that a search has been made
    setStoredInput(questionInput); // Store the input value
    setQuestionInput(""); // Clear the input
    setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab
  } catch (error) {
    console.error("Error fetching question response:", error);
  } finally {
    setIsLoadingLocal(false);
    setIsLoadingNoText(false); // Stop spinner with no text
  }
};

  const fetchGuidedQuestions = async () => {
    setIsLoadingNoText(true); // Start spinner with no text
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-guided-search-questions`, {
        params: {
          session_id: sessionId, // Pass session ID to maintain context
          max_questions: 15,
          user_input: searchInput.trim()
        },
      });
      setGuidedQuestions(response.data.questions || []);
      setGuidedAnswers(new Array(response.data.questions.length).fill(""));
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching guided search questions:", error);
    } finally {
      setIsLoadingNoText(false); // Stop spinner with no text
    }
  };

  const handleGuidedSearchFormSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim() === "") return; // Prevent submission if input is empty
    setIsLoadingNoText(true); // Set loading state
    fetchGuidedQuestions();
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = { ...guidedAnswers };
    newAnswers[index] = value; // Allow spaces
    setGuidedAnswers(newAnswers);
  };

  const handleFreeFormAnswerChange = (index, value) => {
    const newAnswers = { ...guidedAnswers };
    newAnswers[`${index}_custom`] = value; // Allow spaces
    setGuidedAnswers(newAnswers);
  };

  const cleanQuestion = (question) => {
    return question.endsWith(' A:') ? question.slice(0, -3) : question;
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // Set default value for dropdowns to be blank
  const renderDropdownOptions = (options) => [
    <option key="" value=""></option>,
    ...options.map((option, i) => {
      const trimmedOption = option.trim();
      return <option key={i} value={trimmedOption}>{trimmedOption}</option>;
    })
  ];

  const handleModalSubmit = () => {
    const filledFields = guidedQuestions.map((q, i) => {
      let answer = guidedAnswers[i];
      if (answer.trim().toLowerCase() === "other") {
        answer = guidedAnswers[`${i}_custom`] || "other"; // Use custom input if "other" is selected
      }
      if (!answer || q.toLowerCase().includes("free-form")) return null; // Skip if no answer is provided or field name includes "free-form"
      const fieldName = toTitleCase(cleanQuestion(q.split(' A: ')[0].replace('Q: ', '').trim())); // Remove "Q: " and trim spaces
      return `${fieldName}: ${answer}`.replace(/\s+/g, ' '); // Trim spaces from answer and remove double spaces
    }).filter(Boolean).join(', ');

    const combinedInput = `${searchInput}${filledFields ? ', ' + filledFields : ''}`;
    handleSearch(combinedInput, isDeepSearchInitiated); // Use the correct search type
    setIsModalOpen(false);
    resetStates(); // Reset states after form submission
    setIsDeepSearchInitiated(false); // Reset the deep search flag
  };

  const handleDeepSearch = (searchValue) => {
    setIsDeepSearchInitiated(true);
    if (activeTab === "guidedSearch") {
      fetchGuidedQuestions(); // Fetch guided questions and open modal
    } else {
      handleSearch(searchValue, true); // Perform a deep search for other tabs
    }
  };

  return (
    <div className="productFormContainer">
      <div className="tabs" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <span
          className={`tab ${activeTab === "newSearch" ? "active" : ""}`}
          onClick={() => handleTabClick("newSearch")}
          style={{ cursor: 'pointer', textDecoration: activeTab === "newSearch" ? 'underline' : 'none' }}
        >
          New Product
        </span>
        <span
          className={`tab ${activeTab === "similarProduct" ? "active" : ""}`}
          onClick={() => handleTabClick("similarProduct")}
          style={{ cursor: 'pointer', textDecoration: activeTab === "similarProduct" ? 'underline' : 'none', marginLeft: '20px' }}
        >
          Similar Product
        </span>
        <span
          className={`tab ${activeTab === "guidedSearch" ? "active" : ""}`}
          onClick={() => handleTabClick("guidedSearch")}
          style={{ cursor: 'pointer', textDecoration: activeTab === "guidedSearch" ? 'underline' : 'none', marginLeft: '20px' }}
        >
          Guided Search
        </span>
        <span
          className={`tab ${activeTab === "askQuestion" ? "active" : ""}`}
          onClick={() => handleTabClick("askQuestion")}
          style={{ cursor: 'pointer', textDecoration: activeTab === "askQuestion" ? 'underline' : 'none', marginLeft: '20px' }}
        >
          Ask Question
        </span>
      </div>
      <form onSubmit={activeTab === "newSearch" ? handleFormSubmit : activeTab === "similarProduct" ? handleSimilarProductFormSubmit : activeTab === "askQuestion" ? handleQuestionFormSubmit : handleGuidedSearchFormSubmit} className="form">
        {activeTab === "newSearch" && (
          <div className="inputGroup">
            <label className="label">
              <FaSearch className="icon" /> Find a Product:
            </label>
            <p className="description">Enter the product you're looking for.</p>
            <textarea
              value={searchInput}
              onChange={(e) => { handleInputChange(e); handleTextareaInput(e); }}
              className="input"
              required
              rows="1"
              placeholder={searchMadeInTabs.newSearch ? "" : 'Show me the best digital calipers 0.0005" resolution under $200'}
              onKeyDown={(e) => { handleKeyDown(e); handleTextareaKeyDown(e); }} // Add onKeyDown event to textarea
            />
            {searchInput.length > 0 && suggestions.length > 0 && !isLoadingLocal && !isEscPressed && (
              <ul className="suggestionsList">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestionItem"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {activeTab === "similarProduct" && (
          <div className="inputGroup">
            <label className="label">
              <FaSearch className="icon" /> Find a Similar Product:
            </label>
            <p className="description">Enter the link for the product you're trying to match. Please note, some sites with aggressive anti-scraping policies won't currently work. Please flag these links to Chandler!</p>
            <textarea
              value={similarProductLink}
              onChange={handleSimilarProductInputChange}
              className="input"
              required
              rows="1"
              placeholder={searchMadeInTabs.similarProduct ? "" : "https://www.grainger.com/product/29AD41"}
              onKeyDown={handleTextareaKeyDown} // Add onKeyDown event to textarea
            />
            {/^(https?:\/\/[^\s]+)$/.test(similarProductLink) && (
              <div style={{ width: '250px', height: '135px', border: '1px solid #ccc', marginTop: '10px', overflow: 'hidden', borderRadius: '4px' }}>
                <iframe
                  src={similarProductLink}
                  style={{ width: '1920px', height: '1080px', transform: 'scale(0.13)', transformOrigin: '0 0', border: 'none', background: 'none' }}
                  title="Product Preview"
                />
              </div>
            )}
          </div>
        )}
        {activeTab === "askQuestion" && (
          <div className="inputGroup">
            <label className="label">
              <FaSearch className="icon" /> Ask a Question:
            </label>
            <p className="description">Ask a question about the products shown below.</p>
            <textarea
              value={questionInput}
              onChange={handleQuestionInputChange}
              className="input"
              required
              rows="1"
              placeholder={searchMadeInTabs.askQuestion ? "" : "Which one is cheapest?"}
              onKeyDown={handleTextareaKeyDown} // Add onKeyDown event to textarea
            />
          </div>
        )}
        {activeTab === "guidedSearch" && (
          <div className="inputGroup">
            <label className="label">
              <FaSearch className="icon" /> Guided Search:
            </label>
            <p className="description">Briefly describe the product you're looking for.</p>
            <textarea
              value={searchInput}
              onChange={(e) => { handleInputChange(e); handleTextareaInput(e); }}
              className="input"
              required
              rows="1"
              placeholder={searchMadeInTabs.guidedSearch ? "" : 'Digital calipers'}
              onKeyDown={(e) => { handleKeyDown(e); handleTextareaKeyDown(e); }}
            />
            {searchInput.length > 0 && suggestions.length > 0 && !isLoadingLocal && !isEscPressed && (
              <ul className="suggestionsList">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestionItem"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <div className="buttonGroup" style={{ display: 'flex', gap: '10px' }}>
          {activeTab === "newSearch" ? (
            <>
            <button type="submit" className="button" disabled={isLoadingLocal}>Search</button>
            <button type="button" className="button deepSearchButton" disabled={isLoadingLocal} onClick={() => handleDeepSearch(searchInput)}>Deep Search</button>
            </>
          ) : activeTab === "similarProduct" ? (
            <>
            <button type="button" className="button" disabled={isLoadingLocal} onClick={handleSimilarProductDeepSearchSubmit}>Deep Search</button>
            </>
          ) : activeTab === "askQuestion" ? (
            <>
            <button type="submit" className="button">Submit</button>
            </>
          ) : (
            <>
            <button type="submit" className="button" disabled={isLoadingLocal}>Search</button>
            <button type="button" className="button deepSearchButton" disabled={isLoadingLocal} onClick={() => handleDeepSearch(searchInput)}>Deep Search</button>
            </>
          )}
        </div>
        {activeTab === "askQuestion" && response && (
          <div className="response-container">
            <ReactMarkdown>{response}</ReactMarkdown>
          </div>
        )}
        {activeTab !== "askQuestion" && (
          <div className="siteButtons" style={{ marginTop: '10px' }}>
            <div>Search Vendor:</div>
            <button type="button" onClick={() => handleSiteButtonClick("")} className={`button ${specificSite === "" ? "active" : ""}`}>All</button>
            <button type="button" onClick={() => handleSiteButtonClick("grainger.com")} className={`button ${specificSite === "grainger.com" ? "active" : ""}`}>Grainger</button>
            <button type="button" onClick={() => handleSiteButtonClick("digikey.com")} className={`button ${specificSite === "digikey.com" ? "active" : ""}`}>DigiKey</button>
            <button type="button" onClick={() => handleSiteButtonClick("mscdirect.com")} className={`button ${specificSite === "mscdirect.com" ? "active" : ""}`}>MSC Direct</button>
            <button type="button" onClick={() => handleSiteButtonClick("automationdirect.com")} className={`button ${specificSite === "automationdirect.com" ? "active" : ""}`}>Automation Direct</button>
            <button type="button" onClick={() => handleSiteButtonClick("amazon.com")} className={`button ${specificSite === "amazon.com" ? "active" : ""}`}>Amazon</button>
          </div>
        )}
        {description && activeTab !== "askQuestion" && (
          <div className="response-container">
            <div className="search-duration">Thought for {searchDuration} seconds...</div>
            <ReactMarkdown>{description}</ReactMarkdown>
            <button onClick={handleShowMeClick} className="button" style={{ marginBottom: "15px" }}>Show me</button>
          </div>
        )}
      </form>
      {isModalOpen && (
        <div className="customModalOverlay" onClick={handleModalClose}>
          <div className="customModal" onClick={(e) => e.stopPropagation()}>
            <button className="closeButton" onClick={handleModalClose}>&times;</button>
            <div style={{ marginBottom: '10px', fontWeight: 'bold', color: '#333', marginTop: 0 }}>Specifications:</div>
            <label style={{ marginBottom: '12px', color: '#666' }}>All fields are optional.</label>
            <div className="fieldsContainer">
              {guidedQuestions.map((question, index) => {
                const parts = question.split(' A: ');
                const isFreeForm = parts.length === 1 || parts[1].toLowerCase().includes('free-form'); // Check for 'free-form'
                const fieldName = toTitleCase(cleanQuestion(parts[0].replace('Q: ', ''))); // Apply toTitleCase to the field name
                const options = isFreeForm ? [] : parts[1].split(/,\s*/).map(option => option.trim()).filter((option, index, self) => option.toLowerCase() !== 'other' || self.indexOf(option.toLowerCase()) === index);
                return (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <label style={{ flex: '1', marginRight: '10px' }}>{fieldName}</label>
                    {isFreeForm ? (
                      <input
                        type="text"
                        value={guidedAnswers[index] || ''}
                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                        className="input"
                        style={{ flex: '2', marginTop: '0' }}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    ) : (
                      <select
                        value={guidedAnswers[index]}
                        onChange={(e) => handleAnswerChange(index, e.target.value)}
                        className="input"
                        style={{ flex: '2', marginTop: '0' }}
                      >
                        {renderDropdownOptions(options)}
                        <option value="other">Other</option>
                      </select>
                    )}
                    {guidedAnswers[index].trim().toLowerCase() === "other" && (
                      <input
                        type="text"
                        value={guidedAnswers[`${index}_custom`] || ''}
                        onChange={(e) => handleFreeFormAnswerChange(index, e.target.value)}
                        className="input"
                        style={{ flex: '2', marginTop: '0', marginBottom: 0, marginLeft: "10px" }}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <button onClick={handleModalSubmit} className="button">Submit</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductForm;
