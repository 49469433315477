import React, { useState, useEffect } from "react";
import { FaBriefcase } from "react-icons/fa"; // Import an icon from react-icons
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import chevron icons
import ProductList from "./components/ProductList/ProductList";

const Jobs = ({ sessionId, jobs, setJobs }) => {
  const [selectedJobId, setSelectedJobId] = useState(null);

  const fetchJobs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get-jobs?session_id=${sessionId}`);
      const data = await response.json();
      setJobs(data.jobs || {}); // Ensure jobs is always an object
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchJobs(); // Fetch jobs on component mount and whenever sessionId changes

    const intervalId = setInterval(fetchJobs, 15000); // Fetch jobs every 15 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [sessionId]);

  const handleJobClick = (jobId, status) => {
    setSelectedJobId(jobId === selectedJobId ? null : jobId);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "running":
        return "#4d8df5"; // Blue
      default:
        return "#e0e0e0"; // Grey
    }
  };

  const toTitleCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const truncateJobName = (name, maxLength = 50) => {
    return name && name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
  };

  const chevronStyle = {
    fontSize: "1.4rem",
    marginRight: "8px",
    color: "#b0b0b0",
  };

  return (
    <div style={{ width: "100%", padding: "0 20px" }}>
      <div style={{ width: "100%", margin: "0 auto", height: "calc(100vh - 140px)" }}>
        {Object.keys(jobs).length > 0 && (
          <div style={{marginLeft: "20%"}}>
            <h2 style={{ color: "#555", position: "sticky", top: "0", zIndex: 1, fontSize: "1.4rem" }}>
              <FaBriefcase style={{ marginRight: "8px", position: "relative", top: "2px" }} /> Jobs
            </h2>
          </div>
        )}
        {Object.keys(jobs).length === 0 ? (
          <p style={{ 
            color: "#666", 
            textAlign: "center", 
            fontSize: "1.1rem", 
            position: "absolute", 
            top: "50%", 
            left: "50%", 
            transform: "translate(-50%, -50%)" 
          }}>
            There are no search jobs available. Please make a "Deep Search" to kick off a job.
          </p>
        ) : (
          <div style={{ width: "100%" }}>
            {Object.entries(jobs)
              .sort(([, jobA], [, jobB]) => jobB.start_time - jobA.start_time)
              .map(([jobId, job], index) => (
                <div key={jobId} style={{ width: "100%", margin: "0 auto" }}>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <div
                      onClick={() => job.products && job.products.length > 0 && handleJobClick(jobId, job.status)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "white",
                        padding: "22px",
                        boxSizing: "border-box",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        border: job.status.toLowerCase() === "running" ? "2px solid #4d8df5" : "none",
                        boxShadow: job.status.toLowerCase() === "running" ? "0 2px 2px rgba(0, 0, 0, 0.1)" : "0 2px 4px rgba(0, 0, 0, 0.1)",
                        cursor: job.status.toLowerCase() === "running" || !(job.products && job.products.length > 0) ? "default" : "pointer",
                        color: "#555",
                        width: "60%",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <h3 style={{ margin: "0 0 10px 0", color: "#555", fontSize: "1.1rem" }}>{truncateJobName(job.name)}</h3>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "5px", color: "#888", fontSize: "1rem" }}>
                          <p style={{ margin: 0 }}>
                            Status: {toTitleCase(job.status)},
                          </p>
                          <p style={{ margin: "0 5px" }}>
                            Start Time: {formatTimestamp(job.start_time)}
                            {job.end_time && ","}
                          </p>
                          {job.end_time && <p style={{ margin: 0 }}>End Time: {formatTimestamp(job.end_time)}</p>}
                        </div>
                        {job.sample_images && job.sample_images.length > 0 && selectedJobId !== jobId && (
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", maxWidth: "100%", marginTop: "15px" }}>
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                              {job.sample_images.map((image, idx) => (
                                <div
                                  key={idx}
                                  style={{
                                    height: "100px",
                                    width: "auto",
                                    maxWidth: "100px",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={image}
                                    alt="Sample"
                                    style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      {job.status.toLowerCase() !== "running" && job.products && job.products.length > 0 && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {selectedJobId === jobId ? <FaChevronUp style={chevronStyle} /> : <FaChevronDown style={chevronStyle} />}
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedJobId === jobId && job.status.toLowerCase() !== "running" && job.products && job.products.length > 0 && (
                    <div style={{ width: "100%" }}>
                      <ProductList
                        recommendations={job.products || []}
                        sessionId={sessionId}
                        setDescription={() => {}}
                        showSearchResultsTitle={false}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Jobs;
