import React, { useState, useEffect } from "react";
import { FaBars, FaBell, FaRegBell, FaTimes } from "react-icons/fa";

const Header = ({ handleNewSearch, currentPage, setCurrentPage, sessionId }) => {
  const [hasNotifications, setHasNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/get-notifications?session_id=${sessionId}`);
  //       const data = await response.json();
  //       setNotifications(data.notifications || []);
  //       setHasNotifications(data.notifications && data.notifications.length > 0);
  //     } catch (error) {
  //       console.error("Error fetching notifications:", error);
  //     }
  //   };

  //   fetchNotifications();
  //   const interval = setInterval(fetchNotifications, 15000);

  //   return () => clearInterval(interval);
  // }, [sessionId]);

  const alertFakeFeature = () => {
    alert("Product Finder is still in beta testing. For questions or feedback, please email chandler@suppleitco.com");
  };

  return (
    <header style={styles.header}>
      <div style={styles.leftSection}>
        <h1 style={styles.title}>Product Finder</h1>
      </div>
      <div style={styles.rightSection}>
        <span 
          style={currentPage === "search" ? { ...styles.pageLink, ...styles.activePageLink } : styles.pageLink} 
          onClick={() => setCurrentPage("search")}
        >
          Search
        </span>
        <span 
          style={currentPage === "jobs" ? { ...styles.pageLink, ...styles.activePageLink } : styles.pageLink} 
          onClick={() => setCurrentPage("jobs")}
        >
          Jobs
        </span>
        <button 
          style={styles.feedbackButton} 
          onClick={() => { window.open("https://forms.gle/B4gVakEZM87BF4sR9", "_blank") }}
        >
          Send Feedback
        </button>
        <button 
          style={styles.signInButton} 
          onClick={alertFakeFeature}
        >
          Sign In
        </button>
        {hasNotifications ? (
          <FaBell 
            style={{ ...styles.notificationIcon, cursor: "pointer" }} 
            onClick={() => setShowNotifications(!showNotifications)}
          />
        ) : (
          <FaRegBell 
            style={styles.notificationIcon} 
            onClick={() => setShowNotifications(!showNotifications)}
          />
        )}
        <FaBars onClick={alertFakeFeature} style={styles.icon} />
      </div>
      {showNotifications && hasNotifications && (
        <div style={styles.notificationPopup}>
          <div style={styles.notificationHeader}>
            <span>Notifications</span>
            <FaTimes style={styles.closeIcon} onClick={() => setShowNotifications(false)} />
          </div>
          <div style={styles.notificationContent}>
            {notifications.map((notification, index) => (
              <div key={index} style={{ 
                ...styles.notificationRow, 
                ...(index === 0 ? styles.firstNotificationRow : {}), 
                ...(index < notifications.length - 1 ? styles.notificationSeparator : {}), 
                ...(index === notifications.length - 1 ? styles.lastNotificationRow : {}) 
              }}>
                {notification}
              </div>
            ))}
          </div>
        </div>
      )}
    </header>
  );
};

const styles = {
  header: {
    backgroundColor: "#4d8df5", // Updated blue color
    color: "white",
    padding: "20px",
    textAlign: "center",
    width: "100%",
    position: "fixed",
    top: "0",
    left: "0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    zIndex: "1000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  title: {
    fontSize: "1.5rem",
    margin: "0",
  },
  rightSection: {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
  },
  icon: {
    fontSize: "1.5rem",
    cursor: "pointer",
  },
  signInButton: {
    backgroundColor: "white",
    color: "#4d8df5", // Updated blue color
    border: "none",
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "20px",
  },
  feedbackButton: {
    backgroundColor: "white",
    color: "#4d8df5", // Updated blue color
    border: "1px solid #4d8df5", // Updated blue color
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "20px",
    transition: "background-color 0.3s, color 0.3s",
  },
  feedbackButtonHover: {
    backgroundColor: "#4d8df5", // Updated blue color
    color: "white",
  },
  pageLink: {
    color: "white",
    cursor: "pointer",
    marginRight: "20px",
    textDecoration: "none",
  },
  activePageLink: {
    textDecoration: "underline",
  },
  notificationIcon: {
    fontSize: "1.2rem", // Smaller icon size
    marginRight: "20px",
  },
  notificationPopup: {
    position: "absolute",
    top: "70px", // Below the header
    right: "20px",
    backgroundColor: "white",
    color: "#888", // Lighter grey text color
    border: "1px solid #e0e0e0", // Lighter grey border
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    zIndex: "1001",
    padding: "20px", // Added inner padding
    minWidth: "220px", // Slightly larger minWidth
    minHeight: "250px", // 2.5x the original minHeight
    maxWidth: "250px", // Set max width
    textAlign: "left", // Left hanging text
    wordWrap: "break-word", // Enable text wrap
  },
  notificationHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    fontSize: "1.1rem",
    fontWeight: "bold",
    position: "sticky",
    top: "0",
    backgroundColor: "white",
    zIndex: "1",
  },
  notificationHeaderIcon: {
    marginRight: "8px",
  },
  notificationContent: {
    maxHeight: "250px",
    overflowY: "scroll", // Allow scrolling without showing the scroll bar
    scrollbarWidth: "none", // For Firefox
  },
  notificationRow: {
    padding: "10px 0",
    fontSize: "0.9rem", // Smaller text size
  },
  firstNotificationRow: {
    paddingTop: "0", // Remove top padding for the first notification
    marginTop: "0", // Remove top margin for the first notification
  },
  lastNotificationRow: {
    paddingBottom: "0", // Remove bottom padding for the last notification
    marginBottom: "0", // Remove bottom margin for the last notification
  },
  notificationSeparator: {
    borderBottom: "1px solid #e0e0e0", // Lighter grey bottom border as separator
  },
  closeIcon: {
    fontSize: "1.5rem", // Bigger icon size
    cursor: "pointer",
    position: "absolute",
    top: "-3px", // More space around the "x" icon
    right: "-3px", // More space around the "x" icon
    color: "#d3d3d3", // Lighter grey color
  },
};

export default Header;
