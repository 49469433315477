// src/components/ProductList.js
import React, { useState, useEffect } from "react";
import "./ProductList.css";

const ProductList = ({ recommendations, setRecommendations, sessionId, userQuery, setDescription, showSearchResultsTitle=true, setIsLoadingLong, isLoadingLong }) => {
  const [topChoiceIndex, setTopChoiceIndex] = useState(null);
  const [summaries, setSummaries] = useState({});
  const [summariesLoading, setSummariesLoading] = useState(true);

  useEffect(() => {
    if (recommendations.length > 0) {
      const randomIndex = Math.floor(Math.random() * recommendations.length);
      setTopChoiceIndex(randomIndex);
    }
  }, [recommendations]);

  useEffect(() => {
    const fetchSummaries = async () => {
      console.log("Initializing WebSocket connection...");
      const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/ws/summaries`);
      ws.onopen = () => {
        console.log("WebSocket connection opened.");
        ws.send(sessionId);
      };
      ws.onmessage = (event) => {
        const data = event.data;
        console.log(`Received message: ${data}`);
        if (data.startsWith("Product ID:")) {
          const [productIdPart, summaryPart] = data.split(", Summary: ");
          const productIdMatch = productIdPart.match(/Product ID: ([\w-]+)/);
          if (productIdMatch && summaryPart) {
            const productId = productIdMatch[1];
            let summary = summaryPart.trim();
            console.log(`Summary for product ${productId}: ${summary}`);
            setSummaries((prevSummaries) => ({
              ...prevSummaries,
              [productId]: summary,
            }));
          } else {
            console.error("Failed to parse product ID and summary from message.");
          }
        } else if (data.startsWith("Session ID:")) {
          const descriptionPart = data.split(", Description: ")[1];
          if (descriptionPart) {
            setDescription(descriptionPart.trim());
            console.log(`Description: ${descriptionPart.trim()}`);
          } else {
            console.error("Failed to parse description from message.");
          }
        }
      };
      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
      ws.onclose = () => {
        console.info("WebSocket connection closed.");
        setSummariesLoading(false);
      };

      // Cleanup function to close WebSocket connection
      return () => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          console.info("WebSocket connection closed by cleanup.");
        }
      };
    };

    if (recommendations.length > 0) {
      const cleanup = fetchSummaries();
      return () => {
        if (cleanup && typeof cleanup === 'function') {
          cleanup();
        }
      };
    }
  }, [recommendations]);

  const handleViewProduct = async (productId) => {
    setIsLoadingLong(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_link?session_id=${sessionId}&product_id=${productId}`);
      const data = await response.json();
      if (data.additional_link) {
        window.open(data.additional_link, '_blank');
      } else {
        alert("Failed to retrieve the product link.");
      }
    } catch (error) {
      console.error("Error fetching product link:", error);
      alert("An error occurred while fetching the product link.");
    } finally {
      setIsLoadingLong(false);
    }
  };

  const handleDeepSearch = async (query) => {
    setIsLoadingLong(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/deep_search?user_input=${encodeURIComponent(query)}&session_id=${sessionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setRecommendations(data.recommendations || []);
    } catch (error) {
      console.error('Error during deep search:', error);
    } finally {
      setIsLoadingLong(false);
    }
  };

  return (
    <div className="wrapper">
      {recommendations.length > 0 && (
        <>
          {showSearchResultsTitle && (
            <>
              <h3 className="resultsTitle">Search Results:</h3>
              {userQuery && userQuery.length <= 90 && (
                <p className="searchQuery">
                  Showing results for "{userQuery}".&nbsp;
                  <span 
                    className="deepSearchLink"
                    onClick={() => handleDeepSearch(userQuery)}
                  >
                    Click here to Deep Search
                  </span>
                </p>
              )}
            </>
          )}
          <div className="container">
            {recommendations.map((product, index) => (
              <div key={index} className="product">
                <h4>{product.title}</h4>
                {product.image && (
                  <img src={product.image} alt={product.title} className="image" />
                )}
                <p className="price">{product.price}</p>
                <p className="vendor">{product.vendor}</p>
                <div className="summary">
                  {product.summary ? (
                    <ul>
                      {product.summary.split("\n").map((item, idx) => item && <li key={idx}>{item.replace(/^- /, '')}</li>)}
                    </ul>
                  ) : (
                    summaries[product.id] && summaries[product.id] !== "no content" && !summaries[product.id].endsWith("ack") && (
                      <ul>
                        {summaries[product.id].split("\n").map((item, idx) => item && <li key={idx}>{item.replace(/^- /, '')}</li>)}
                      </ul>
                    )
                  )}
                </div>
                <button 
                  onClick={() => handleViewProduct(product.id)} 
                  className="link"
                  disabled={isLoadingLong}
                >
                  View Product
                </button>
                <a 
                  href={`https://www.google.com/search?tbm=shop&q=${encodeURIComponent(product.title)}`} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="googleShoppingLink"
                >
                  Find Similar Online
                </a>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductList;
